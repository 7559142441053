<template>
  <div class="logining">
    <!-- 头 -->
    <!-- <div class="header">
      <div class="imgs">
        <img src="../../assets/logo1.png" alt />
      </div>
      <div class="title">
        <span>健康数据管理中心</span>
      </div>
    </div> -->
    <!-- 表单 -->
    <div class="contentImg">
      <div class="imgBanner">
        <div class="logoMain">
          <div class="logo">
            <img src="../../assets/logob.png" />
            <span>康康科技</span>
          </div>
          <div class="loginLine"></div>
          <div class="logoText">
            <span>international</span>
          </div>
        </div>
        <div class="bannerText">
          <div class="bText_text">一站式基础设施解决方案</div>
          <div class="bText_textwo">全球拓展业务</div>
          <div class="bText_line"></div>
          <div class="bText_bottom">
            <span>Copyright©2018-2022 康康科技版权所有</span>
          </div>
        </div>
      </div>
      <div class="formList">
        <div class="formList_min">
          <div class="formList_text">康康智能硬件开放平台</div>
          <div class="formList_center">服务中心</div>
          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            label-width="10px"
            class="demo-ruleForm"
          >
            <el-form-item prop="account">
              <!-- <span slot="label">
                <img class="image" src="../../assets/account.png" alt />
              </span> -->
              <el-input
                v-model="ruleForm.phone"
                placeholder="请输入账号"
                prefix-icon="el-icon-user"
                autocomplete="off"
                class="phones"
              ></el-input>
            </el-form-item>
            <el-form-item prop="pwd">
              <!-- <span slot="label">
                <img class="image" src="../../assets/password.png" alt />
              </span> -->
              <el-input
                type="password"
                v-model="ruleForm.password"
                prefix-icon="el-icon-lock"
                placeholder="请输入密码"
                autocomplete="off"
                class="passwords"
              ></el-input>
              <div @click="forgetpwd" class="forgetpwds">找回密码</div>
            </el-form-item>
            <template v-if="!show">
              <el-form-item class="imagestext" prop="pwd">
                <el-input
                  placeholder="输入验证码"
                  v-model="ruleForm.code"
                  autocomplete="off"
                ></el-input>
                <img :src="imageText" alt="" @click="imgtext" />
              </el-form-item>
            </template>
          </el-form>
          <div class="but">
            <el-button type="primary" @click="loginShan('ruleForm')"
              >登录</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jin: "",
      ruleForm: {
        phone: "",
        password: "",
        code: "",
      },
      result: "",
      show: true,
      imageText: "",
      rules: {
        Account: [{ trigger: "blur" }],
        pass: [{ trigger: "blur" }],
      },
      token: "",
      canLogin: false,
    };
  },
  created() {
    if (this.result > 2) {
      this.show = false;
      this.graphicCode();
    }
    this.jin = setInterval(() => {
      if (document.readyState === "complete") {
        this.canLogin = true;
      } else {
        this.canLogin = false;
      }
    }, 1000);
  },
  // watch:{
  //   //路由改变的时候监听
  //   $route(to,from) {
  //     console.log('===0',to,from);
  //     if(to.path=='/system_query/central'){
  //       this.$router.go(0);
  //     }
  //   },
  // },
  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.keyDown);
    //清除缓存
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
    console.log("走到destroyed");
  },
  methods: {
    callback() {},
    // 图文验证接口
    graphicCode() {
      let that = this;
      that
        .$http({
          url: "/foo/login/graphicCode",
          params: { phone: that.ruleForm.phone },
          method: "POST",
          responseType: "blob", // 表明返回服务器返回的数据类型
        })
        .then((res) => {
          console.log("图文验证", res);
          if (res.data) {
            //如果返回的有值，则使用返回的底图
            // 先将二进制的流转为blob，注意后面type一定要设置为图片格式
            let blob = new Blob([res.data], { type: "image/png" });
            var Fr = new FileReader();
            Fr.readAsDataURL(blob);
            Fr.onload = (event) => {
              //这个就是转换为的base64图片地址
              that.imageText = event.target.result;
              // console.log(that.imageText)
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 回车登录
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        this.loginShan();
      }
    },
    // 登录跳转首页
    loginShan() {
      if (this.canLogin == true) {
        let that = this;
        if (that.ruleForm.phone == "" || that.ruleForm.password == "") {
          that.$message.error("请输入用户名和密码");
        } else {
          // console.log("路径：", base.login);
          that
            .$http({
              url: "/foo/login/login",
              params: that.ruleForm,
              method: "POST",
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            })
            .then((res) => {
              console.log("登录：", res.data);
              // let ids = res.data.result.id;
              // let Names = res.data.result.name;
              if (res.data.code === 200) {
                this.$router.push("/AlarmApiManage/menu/homePage");
                //token 存储本地
                localStorage.setItem(
                  "tokenShan",
                  JSON.stringify(res.data.result.token)
                );
                localStorage.setItem(
                  "shanInfor",
                  JSON.stringify(res.data.result)
                );
                // that.loginmenu();
              } else {
                that.$message({
                  message: "登陆失败,请输入正确的用户名或密码",
                  type: "success",
                });
                that.$router.push("/AlarmApiManage");
                // that.$router.push("/");
                that.passworderror();
                if (that.result > 2) {
                  if (that.ruleForm.code == "") {
                    that.$message.error("请输入验证码");
                  }
                } else if (res.data.msg == "请输入正确的验证码") {
                  that.$message.error("请输入正确的验证码");
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        alert("页面正在加载中，请稍后登录");
      }
    },
    // 点击图文
    imgtext() {
      this.graphicCode();
    },
    // 密码错误三次
    passworderror() {
      this.$http({
        url: "/foo/login/errorCount",
        params: { phone: this.ruleForm.phone },
        method: "POST",
      }).then((res) => {
        console.log("密码错误三次", res);
        if (res.data.result > 2) {
          this.show = false;
          this.graphicCode();
        }
      });
    },
    // 忘记密码
    forgetpwd() {
      this.$router.replace("./AlarmApiManage/menu/Retrievepassword");
    },
    // 登陆成功菜单权限
    loginmenu() {
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log('token',that.token)
      //console.log('token',that.token)
      that
        .$http({
          url: "/api/menu/getUserMenu",
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            //把token放到请求头才能请求，这里的'Bearer '表示是后台希望更加的安全，依据后台给的信息看到底是加还是不加
            // 'Authorization': 'Bearer ' + that.token,
            token: that.token,
          },
        })
        .then((res) => {
          //console.log("权限", res.data);
          if (res.data.code == 200) {
            window.localStorage.setItem(
              "houtai",
              JSON.stringify(res.data.result)
            );
            this.$router
              .push({ path: "/system_query/central" })
              .catch((err) => {
                console.log("err", err);
              });
          } else {
            let idNames = JSON.parse(localStorage.getItem("loginuser"));
            that
              .$http({
                url: "/too/center/weblog/save",
                params: {
                  callback: "",
                  errorCode: res.data.code,
                  errorExplain: res.data.msg,
                  interfaceUrl: res.config.url,
                  menuName: "客服系统-菜单权限",
                  menuUrl: this.$route.path,
                  params: res.config.params,
                  source: "客服系统",
                  userId: idNames.id,
                  userName: idNames.name,
                },
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                },
                method: "get",
              })
              .then((res) => {
                if (res.data.code === 200) {
                  console.log();
                }
              });
          }
          if (res.data.code == 4001) {
            this.$router.push({ path: "@/shanxiLogin.vue" }).catch((err) => {
              console.log("err", err);
            });
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.logoMain {
  width: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 7%;
}
.logo {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.logo img {
  width: 35px;
  margin-right: 8px;
  opacity: 0.94;
}
.logo span {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  text-shadow: 0px 0px 1px #fff;
  opacity: 0.8;
}
.loginLine {
  width: 1px;
  height: 35px;
  background: #fff;
  text-shadow: 0px 0px 8px #fff;
  opacity: 0.6;
  margin: 0px 12px;
}
.logoText {
}
.logoText span {
  font-size: 14px;
  color: #fff;
  text-shadow: 0px 0px 1px #fff;
  opacity: 0.8;
}
.contentImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 700px;
  min-width: 1200px;
  overflow: auto;
  transition: height 0.15s linear 0s, top 0.15s linear 0s;
}
.imgBanner {
  position: absolute;
  top: 0;
  height: 100%;
  width: calc(100% - 421px);
  padding: 43px 0 0 57px;
  background: url(https://isolutions.cmi.chinamobile.com/my/asset/image/bd8db902.png)
    no-repeat;
  background-size: cover;
}
.formList {
  position: absolute;
  top: 0;
  right: 0;
  width: 421px;
  height: 100%;
  background: #fff;
}
.formList_min {
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-68%);
  transform: translateY(-68%);
  padding: 0 60px;
  text-align: center;
}
.formList_text {
  font-size: 30px;
  color: #0085d0;
  font-weight: bold;
  margin: 20px 0 10px;
}
.formList_center {
  margin-bottom: 40px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  font-size: 24px;
  color: #0085d0;
}
.bannerText {
  padding-left: 5%;
  font-size: 30px;
  color: #fff;
}
.bText_text {
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
}
.bText_textwo {
  color: #a2e744;
  font-weight: 400;
  line-height: 1.5;
  font-size: 30px;
}
.bText_line {
  width: 50px;
  height: 4px;
  background: #fff;
  margin: 16px 0;
}
.bText_bottom {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
}
::v-deep .el-input__inner:-webkit-autofill {
  transition: background-color 50000s ease-in-out 0s;
}
/deep/.el-input__inner {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #dcdfe6;
  font-size: 15px;
}
/deep/ .el-input__prefix {
  left: 2px;
  font-size: 18px;
}
.forgetpwds {
  float: right;
  cursor: pointer;
  color: #0085d0;
  font-weight: 500;
  margin-top: 10px;
}
.el-button--primary {
  color: #fff;
  background-color: #0085d0;
  border-color: #0085d0;
  width: 100%;
  height: 44px;
  font-size: 16px;
}
.logining {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 1360px;
  overflow-x: auto;
  overflow-y: auto;
  .header {
    margin-top: 100px;
    .imgs {
      text-align: center;
      img {
        width: 356px;
        height: 66px;
      }
    }
    .title {
      font-size: 40px;
      text-align: center;
      color: #91aca7;
      margin-top: 40px;
      letter-spacing: 8px;
      span {
        font-family: "ExtraLight";
      }
    }
  }
  .main {
    width: 693px;
    margin: 0 auto;
    .el-button {
      height: 43px;
      width: 109px;
      background: #d78673;
      font-size: 18px;
      border: none;
      border-radius: 33px;
    }
    .el-form {
      margin-top: 235px;
      .el-input {
        position: relative;
        font-size: 14px;
        display: inline-block;
        width: 81%;
      }
      .el-input__inner {
        width: 440px;
        border: transparent;
        border-radius: 0;
        border-bottom: 2px solid #d6d6d6;
        margin-bottom: 20px;
        margin-left: 25px;
        background: transparent;
      }
      .image {
        width: 34px;
        height: 43px;
      }
      .imagestext {
        display: flex;
        .el-input {
          width: 250px;
        }
        .el-input__inner {
          width: 200px;
        }
      }
    }
    .but {
      text-align: center;
      margin-top: 80px;
      .el-button {
        height: 66px;
        width: 190px;
        background: #d78673;
        font-size: 28px;
        border: none;
        border-radius: 33px;
      }
      .forgetpwd {
        cursor: pointer;
        display: block;
        margin-top: 80px;
        font-size: 23px;
        color: #92aca7;
      }
    }
  }
  // 弹框
  .el-dialog {
    width: 35%;
    .el-dialog__title {
      color: #92aca7;
    }
    .el-form-item__label {
      color: #a8a8a8;
      font-size: 17px;
    }
    .el-input {
      width: 350px;
    }
  }
  .pwdform {
    margin-left: 130px;
  }
}
</style>