import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 取消默认样式
import './assets/reset.css'
// 引入element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Message } from "element-ui";
import { MessageBox } from "element-ui";
Vue.use(ElementUI);
import 'vue-json-viewer/style.css';
import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer);
// 引入ant-design
// import 'ant-design-vue'
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$antdconfirm = Vue.prototype.$confirm
// import './styles/index.less';
// 引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
// 引入axios
import axios from 'axios';
Vue.prototype.$http = axios;
// const service = axios.create({
//   baseURL:'/api',//根路径
//   timeout:500000 //请求超时
// })
// Vue.prototype.$service=service;
// 时间总线
Vue.prototype.$bus = new Vue()
// 时间格式化
import dateTime from "./api/dateTime.js"
for (let key in dateTime) {
  Vue.filter(key, dateTime[key]);
}

Vue.config.productionTip = false;

// //获取本地存储
// let token = JSON.parse(localStorage.getItem('token'));
// if (token) {
//   store.commit('LoginModule/setToken', token)
// }

// let menuList = JSON.parse(sessionStorage.getItem('houtai'));
// if (menuList) {
//   store.commit('routeModule/setMuenList', menuList)
// }
//防抖处理-立即执行
const on = Vue.prototype.$on
Vue.prototype.$on = function (event, func) {
  let timer
  let flag = true
  let newFunc = func
  if (event === 'click') {
    newFunc = function () {
      if (flag) {
        func.apply(this, arguments)
        flag = false
      }
      clearTimeout(timer)
      timer = setTimeout(function () {
        flag = true
      }, 500)
    }
  }
  on.call(this, event, newFunc)
}
Vue.config.errorHandler = function (err, vm, info) {
  // 参数解析：
  // 1.err：错误对象；
  // 2.vm：Vue实例；
  // 3.info：Vue 特定的错误信息，比如错误所在的生命周期钩子
  // 错误被捕获后，不会再在控制台输出错误信息，可以补输出：
  console.log(vm, '查询vm输出')
  console.log(info, '查询info输出')
  console.error(err, '收集的错误信息')
  // 然后完成错误上报，一般是发送到一个收集错误的 API 接口
  // 如有必要，你还可以把 navigator 对象(客户端信息)一起上报
}
// Vue.config.warnHandler = function (msg, vm, trace) {
//   // `trace` 是组件的继承关系追踪
//   console.warn('warn123', msg, vm, trace)
//   // 逻辑处理
// }

window.onerror = function (errorMessage, scriptURI, lineNumber, columnNumber, errorObj) {
  var context = "错误路径：" + window.location.href + ";"
    + "错误信息：" + errorMessage + ";"
    + "出错文件：" + scriptURI + ";"
    + "出错行号：" + lineNumber + ";"
    + "出错列号：" + columnNumber + ";"
    + "错误详情：" + errorObj + "。";
  console.log(context, 'context')
}
// 将cancel,挂载到vue原型上
Vue.prototype.cancel = function () {
  // 获取缓存的 请求取消标识 数组，取消所有关联的请求
  let cancelArr = window.axiosCancel;
  cancelArr = cancelArr || [];
  cancelArr.forEach((ele, index) => {
    ele.cancel("取消了请求")  // 在失败函数中返回这里自定义的错误信息
    delete window.axiosCancel[index]
  })
}

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
