<template>
  <div>
    <iframe
      :src="mapUrl"
      scrolling="auto"
      frameborder="0"
      style="width: 100%; height:100vh "
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mapUrl: "",
    };
  },
  mounted() {
    this.mapUrl = `/static/first.html`;
  },
};
</script>

<style lang="less" scoped>
</style>