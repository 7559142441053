import Vue from 'vue'
import VueRouter from 'vue-router'
import AlarmApiManage from '../views/AlarmApiManage/shanxiLogin.vue'
import first from '../views/AlarmApiManage/menu/first.vue'
// import store from '../store'
//解决重复跳转路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

export const routes = [
  // {
  //   path: '/',
  //   redirect:'/first',
  // },
  {
    path: '/',
    name: 'first',
    component: first,
  },
  // {
  //   path: '/',
  //   name: 'AlarmApiManage',
  //   component: AlarmApiManage,
  // },
  {
    path: '/AlarmApiManage',
    name: 'AlarmApiManage',
    component: AlarmApiManage,
  },
  {
    path: '/AlarmApiManage/menu/Retrievepassword',
    name: 'Retrievepassword',
    component: () => import('@/views/AlarmApiManage/menu/Retrievepassword.vue'),
  },
  {
    path: '/AlarmApiManage/menu/homePage',
    name: 'AlarmApiManage',
    component: () => import('@/views/AlarmApiManage/adminMenu/admins.vue'),
    meta: {
      title: '首页',
    },
    children: [
      {
        path: '/AlarmApiManage/menu/homePage',
        name: 'homePage',
        component: () => import('@/views/AlarmApiManage/menu/homePage.vue'),
        meta: {
          title: '数量统计',
          requiresAuth: true
        }
      },
      // {
      //   path: '/AlarmApiManage/menu/homePage',
      //   name: 'homePage',
      //   component: () => import('@/views/AlarmApiManage/menu/homePage.vue'),
      //   meta: {
      //     title: '数量统计',
      //     requiresAuth: true
      //   }
      // },
      {
        path: '/AlarmApiManage/menu/Alarmhistory',
        name: 'Alarmhistory',
        component: () => import('@/views/AlarmApiManage/menu/Alarmhistory.vue'),
        meta: {
          title: '报警历史',
          requiresAuth: true
        }
      },
      {
        path: '/AlarmApiManage/menu/Keymanagement',
        name: 'Keymanagement',
        component: () => import('@/views/AlarmApiManage/menu/Keymanagement.vue'),
        meta: {
          title: '秘钥管理',
          requiresAuth: true
        }
      },
      {
        path: '/AlarmApiManage/menu/subscriptionAdmin',
        name: 'subscriptionAdmin',
        component: () => import('@/views/AlarmApiManage/menu/subscriptionAdmin.vue'),
        meta: {
          title: '订阅管理',
          requiresAuth: true
        }
      },
      {
        path: '/AlarmApiManage/menu/sleepAdmin',
        name: 'sleepAdmin',
        component: () => import('@/views/AlarmApiManage/menu/sleepAdmin.vue'),
        meta: {
          title: '睡眠管理',
          requiresAuth: true
        }
      },
      {
        path: '/AlarmApiManage/menu/userManagement',
        name: 'userManagement',
        component: () => import('@/views/AlarmApiManage/menu/userManagement.vue'),
        meta: {
          title: '用户管理',
          requiresAuth: true
        }
      },
      {
        path: '/AlarmApiManage/menu/deviceManagement',
        name: 'deviceManagement',
        component: () => import('@/views/AlarmApiManage/menu/deviceManagement.vue'),
        meta: {
          title: '设备管理',
          requiresAuth: true
        }
      },
      {
        path: '/AlarmApiManage/menu/log',
        name: 'log',
        component: () => import('@/views/AlarmApiManage/menu/log.vue'),
        meta: {
          title: 'API日志',
          requiresAuth: true
        }
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// //全局守卫
router.beforeEach((to, from, next) => {
  next()
  console.log(to, from, next);
})

export default router
